<script>
  import { createNamespacedHelpers } from 'vuex'
  import { debounce } from 'lodash'
  import api from '@/api'
  import recaptchaService from '@/services/recaptcha'

  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  /** Form available through ProductActions (on the PDP). It submits a Freeform form and sends an email.
   * TODO: Refactor to pull from Freeform instead of hardcoding.
   */

  export default {
    name: 'ProductQuoteForm',

    props: {
      /** Hash and honeypot for the form */
      form: {
        type: Object,
        required: true,
      },
      priceForm: {
        type: Object,
        required: true,
      },
      thumb: {
        type: String,
        default: '',
      },
      /** The product or variant ID */
      productId: {
        type: [Number, String],
        required: false,
        default: null,
      },
      basePrice: {
        type: Number,
        default: 0,
      },
      /** Name of the product */
      productTitle: {
        type: String,
        required: true,
      },
      /** Variant SKU (passed in form data) */
      variantSku: {
        type: String,
        default: '',
      },
      /** Name of the product brand, for the confirmation message. */
      brandTitle: {
        type: String,
        required: true,
        default: '',
      },
      /** Selected options from the product builder */
      allOptions: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      allOptionsDetailed:{
        type:Array,
        default:() => ([]),
        required:false
      },
      /** Link to the product (passed in form data) */
      productUrl: {
        type: String,
        required: true,
      },
      purchasable: {
        type: Boolean,
        default: false,
      },
      variants: {
        type: Array,
        required: false,
        default: () => [],
      },
    },

    data() {
      return {
        fields: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          individualOrCompany: '',
          companyName: '',
          state: '',
          additionalInfo: '',
          newsletter: true,
          quote: false,
          similarItems: false,
          isTrade: false,
        },
        errors: [],
        isSending: false,
        isSent: false,
        errorMsg: '',
        success: null,
        submitCount: 0,
        submitPriceCount: 0,
        checkedOrNot: true,
        hasPrice: false,
        quoteSelected: false,
      }
    },

    watch: {
      'fields.quote'() {
        if (!this.purchasable && this.hasPrice) {
          if (this.fields.quote) {
            this.quoteSelected = true
          } else {
            this.quoteSelected = false
          }
        }
      },
    },

    computed: {
      ...mapGlobalGetters(['loggedIn', 'currentUser', 'isTradeAccount']),
      forChecked() {
        return this.checkedOrNot
      },
      defaultFieldValues() {
        return {
          firstName: this.currentUser?.firstName ?? '',
          lastName: this.currentUser?.lastName ?? '',
          email: this.currentUser?.email ?? '',
          phone: this.isTradeAccount ? this.currentUser?.businessPhone ?? '' : '',
          individualOrCompany: this.loggedIn && !this.isTradeAccount ? 'Individual' : 'Company',
          companyName: this.isTradeAccount ? this.currentUser?.businessName ?? '' : '',
          additionalInfo: '',
          newsletter: true,
          quote: false,
          similarItems: false,
          isTrade: this.isTradeAccount,
        }
      },
      prettyOptions() {
        return JSON.stringify(this.allOptions)
      },
      prettyDetailedOptions() {
        return JSON.stringify(this.allOptionsDetailed)
      }
    },

    created() {
      const defaults = this.variants.filter((item) => item.isDefault)
      if (defaults.length) {
        if (defaults[0].basePrice > 0) {
          this.hasPrice = true
        }
      }
      this.resetForm()
    },

    async mounted() {
      // Check if reCAPTCHA script loads
      await recaptchaService.loadRecaptcha();
    },

    methods: {
      getErrors(property) {
        const errors = []
        if (!this.errors.length) {
          return errors
        }
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i].key === property) {
            errors.push(this.errors[i].message)
          }
        }
        return errors
      },

      validate(property) {
        if (!property) {
          this.errors = []
        } else {
          this.errors = this.errors.filter((item) => item.key !== property)
        }
        if ((!property || property === 'firstName') && !this.fields.firstName) {
          this.errors.push({ key: 'firstName', message: 'Your first name is required' })
        }
        if (!property || property === 'additionalInfo') {
          if (!this.isTradeAccount) {
            if (!this.purchasable && this.hasPrice) {
              if (!this.fields.quote) {
                if (!this.fields.additionalInfo) {
                  this.errors.push({ key: 'additionalInfo', message: 'Field is required' })
                }
              }
            } else {
              if (!this.fields.additionalInfo) {
                this.errors.push({ key: 'additionalInfo', message: 'Field is required' })
              }
            }
          } else {
            if (!this.fields.additionalInfo) {
              this.errors.push({ key: 'additionalInfo', message: 'Field is required' })
            }
          }
        }
        if ((!property || property === 'state') && !this.fields.state) {
          this.errors.push({ key: 'state', message: 'Your state is required' })
        }
        if ((!property || property === 'lastName') && !this.fields.lastName) {
          this.errors.push({ key: 'lastName', message: 'Your last name is required' })
        }
        if (!property || property === 'email') {
          if (!this.fields.email) {
            this.errors.push({ key: 'email', message: 'Your email address is required' })
          }
          if (!this.validateEmail(this.fields.email)) {
            this.errors.push({ key: 'email', message: 'Please include a valid email address' })
          }
        }
        if ((!property || property === 'individualOrCompany') && !this.fields.individualOrCompany) {
          this.errors.push({
            key: 'individualOrCompany',
            message: 'Please select either "Individual" or "Company"',
          })
        }
        if (
          this.fields.individualOrCompany === 'Company' &&
          (!property || property === 'companyName') &&
          !this.fields.companyName
        ) {
          this.errors.push({ key: 'companyName', message: 'Company name is required' })
        }
      },

      debounceValidation: debounce(function (property) {
        this.validate(property)
      }, 500),

      validateEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        return reg.test(email)
      },

      handleNewsletterChange(val) {
        this.fields.newsletter = val
      },

      handleQuoteChange(val) {
        this.fields.quote = val
      },

      handleSimilarItemsChange(val) {
        this.fields.similarItems = val
      },

      resetForm() {
        this.fields = {
          ...this.defaultFieldValues,
        }
        this.errors = []
        this.isSending = false
        this.isSent = false
      },

      async submitForm() {
        this.validate();

        if (this.errors.length === 0) {
          try {
            this.isSending = true;

            // Try to execute reCAPTCHA
             // Get reCAPTCHA token
            const recaptchaToken = await recaptchaService.executeRecaptcha('product_quote');
              const splittedDomain = this.thumb.split('/')[2]
              const splittedPath = this.thumb.split('/')[3]
              const splittedImageUrl = this.thumb.split('/')[4]
              const protocol = this.thumb.split('/')[0]
              // Add token to your existing params
              const params = {
                formHash: this.form.hash,
                leadSource: 'Website',
                productURL: this.productUrl,
                product: this.productTitle,
                variantSku: this.variantSku,
                brand: this.brandTitle,
                name: this.fields.firstName,
                lastName: this.fields.lastName,
                email: this.fields.email,
                phone: this.fields.phone,
                individualOrCompany: this.fields.individualOrCompany,
                companyName: this.fields.companyName,
                state: this.fields.state,
                additionalInfo: this.fields.additionalInfo,
                options: this.prettyOptions,
                optionsDetailed: this.prettyDetailedOptions,
                newsletter: this.fields.newsletter,
                quote: this.fields.quote,
                similarItems: this.fields.similarItems,
                isTrade: this.isTradeAccount,
                typeOfProject: this.fields.typeOfProject,
                productImageDomainPart: protocol + '//' + splittedDomain,
                productImagePathPart: '/' + splittedPath,
                productImageFilenamePart: '/' + splittedImageUrl,
                'g-recaptcha-response': recaptchaToken
              }
              if (this.form.honeypot) {
                params[this.form.honeypot.name] = this.form.honeypot.hash
              }



              const priceParams = {
                formHash: this.priceForm.hash,
                leadSource: 'Website',
                urlOfProduct: this.productUrl,
                product: this.productTitle,
                productBrand: this.brandTitle,
                price: '$' + this.basePrice,
                firstName: this.fields.firstName,
                lastName: this.fields.lastName,
                email: this.fields.email,
                phone: this.fields.phone,
                individualOrCompany: this.fields.individualOrCompany,
                companyName: this.fields.companyName,
                state: this.fields.state,
                newsletter: this.fields.newsletter,
                quote: this.fields.quote,
                similarItems: this.fields.similarItems,
                isTrade: this.isTradeAccount,
                productImageDomainPart: protocol + '//' + splittedDomain,
                productImagePathPart: '/' + splittedPath,
                productImageFilenamePart: '/' + splittedImageUrl,
                'g-recaptcha-response': recaptchaToken,
              }

              if (this.priceForm.honeypot) {
                priceParams[this.priceForm.honeypot.name] = this.priceForm.honeypot.hash
              }

              // Send the appropriate GTM event to track leads
              if (this.$gtm.enabled()) {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                  event: 'Product_Inquiry',
                  email: this.currentUser ? this.currentUser.email : this.fields.email,
                  product_id: this.productId,
                  product_name: this.productTitle,
                  product_sku: this.variantSku,
                })
              }
              if (!this.purchasable) {
                if (this.hasPrice && !this.isTradeAccount) {
                  if (this.fields.quote) {
                    api.post('freeform/submit', priceParams).then((response) => {
                      if (!response.data.success) {
                        this.submitCount++
                        this.success = false
                        this.isSending = true
                        if (this.submitCount < 2) {
                          api.post('freeform/submit', priceParams).then((response) => {
                            if (response.data.success) {
                              params['priceEmailSentSuccess'] = 'Yes'
                              params['additionalInfo'] = ''
                              api.post('freeform/submit', params).then((response) => {
                                if (!response.data.success) {
                                  this.submitCount++
                                  this.success = false
                                  this.isSending = true
                                  if (this.submitCount < 3) {
                                    api.post('freeform/submit', params).then((response) => {
                                      if (response.data.success) {
                                        this.isSending = false
                                        this.isSent = true
                                        api.post('/klaviyoconnect/api/track', {
                                          event: {
                                            name: "Subscribed to 'Interested in Product'",
                                            product: {
                                              brand: this.brandTitle,
                                              productTitle: this.productTitle,
                                              variantSku: this.variantSku,
                                              productUrl: this.productUrl,
                                              similarItems: this.fields.similarItems,
                                            },
                                          },
                                          profile: {
                                            'Similar Items': this.fields.similarItems,
                                            'User Type': this.fields.individualOrCompany,
                                          },
                                          list: this.fields.newsletter ? 'RRrbrf' : '',
                                          email: this.fields.email,
                                        })
                                      }
                                    })
                                     this.isSending = false
                                        this.isSent = true
                                  }
                                }
                              })
                            } else {
                              params['priceEmailSentSuccess'] = ''
                              api.post('freeform/submit', params).then((response) => {
                                if (!response.data.success) {
                                  this.submitCount++
                                  this.success = false
                                  this.isSending = true
                                  if (this.submitCount < 3) {
                                    api.post('freeform/submit', params).then((response) => {
                                      if (response.data.success) {
                                        this.isSending = false
                                        this.isSent = true
                                        api.post('/klaviyoconnect/api/track', {
                                          event: {
                                            name: "Price Email Not Sent'",
                                            product: {
                                              brand: this.brandTitle,
                                              productTitle: this.productTitle,
                                              variantSku: this.variantSku,
                                              productUrl: this.productUrl,
                                              similarItems: this.fields.similarItems,
                                            },
                                          },
                                          list: this.fields.newsletter ? 'RRrbrf' : '',
                                          email: this.fields.email,
                                        })
                                      }
                                    })
                                  }
                                }
                              })
                            }
                          })
                        }
                      }
                    })
                  } else {
                    // Send the data to Freeform
                    params['priceEmailSentSuccess'] = ''
                    api.post('freeform/submit', params).then((response) => {
                      if (response.status !== 200) {
                        this.errorMsg = 'Oops! Something went wrong. Please try again.'
                        this.success = false
                      } else if (!response.data.success) {
                        this.submitCount++
                        this.success = false
                        this.isSending = true
                        if (this.submitCount < 2) {
                          api.post('freeform/submit', params).then((response) => {
                            if (response.data.success) {
                              this.isSending = false
                              this.isSent = true
                              api.post('/klaviyoconnect/api/track', {
                                event: {
                                  name: "Subscribed to 'Interested in Product'",
                                  product: {
                                    brand: this.brandTitle,
                                    productTitle: this.productTitle,
                                    variantSku: this.variantSku,
                                    productUrl: this.productUrl,
                                    similarItems: this.fields.similarItems,
                                  },
                                },
                                profile: {
                                  'Similar Items': this.fields.similarItems,
                                  'User Type': this.fields.individualOrCompany,
                                },
                                list: this.fields.newsletter ? 'RRrbrf' : '',
                                email: this.fields.email,
                              })
                            }
                          })
                        } else {
                          this.errorMsg = response.data.errors[0] || response.data.formErrors[0]
                        }
                      }
                    })
                  }
                } else {
                  // Send the data to Freeform
                  params['priceEmailSentSuccess'] = ''
                  api.post('freeform/submit', params).then((response) => {
                    if (response.status !== 200) {
                      this.errorMsg = 'Oops! Something went wrong. Please try again.'
                      this.success = false
                    } else if (!response.data.success) {
                      this.submitCount++
                      this.success = false
                      this.isSending = true
                      if (this.submitCount < 2) {
                        api.post('freeform/submit', params).then((response) => {
                          if (response.data.success) {
                            this.isSending = false
                            this.isSent = true
                            api.post('/klaviyoconnect/api/track', {
                              event: {
                                name: "Subscribed to 'Interested in Product'",
                                product: {
                                  brand: this.brandTitle,
                                  productTitle: this.productTitle,
                                  variantSku: this.variantSku,
                                  productUrl: this.productUrl,
                                  similarItems: this.fields.similarItems,
                                },
                              },
                              profile: {
                                'Similar Items': this.fields.similarItems,
                                'User Type': this.fields.individualOrCompany,
                              },
                              list: this.fields.newsletter ? 'RRrbrf' : '',
                              email: this.fields.email,
                            })
                          }
                        })
                      } else {
                        this.errorMsg = response.data.errors[0] || response.data.formErrors[0]
                      }
                    }
                  })
                }
              } else {
                // Send the data to Freeform
                params['priceEmailSentSuccess'] = ''
                api.post('freeform/submit', params).then((response) => {
                  if (response.status !== 200) {
                    this.errorMsg = 'Oops! Something went wrong. Please try again.'
                    this.success = false
                  } else if (!response.data.success) {
                    this.submitCount++
                    this.success = false
                    this.isSending = true
                    if (this.submitCount < 2) {
                      api.post('freeform/submit', params).then((response) => {
                        if (response.data.success) {
                          this.isSending = false
                          this.isSent = true
                          api.post('/klaviyoconnect/api/track', {
                            event: {
                              name: "Subscribed to 'Interested in Product'",
                              product: {
                                brand: this.brandTitle,
                                productTitle: this.productTitle,
                                variantSku: this.variantSku,
                                productUrl: this.productUrl,
                                similarItems: this.fields.similarItems,
                              },
                            },
                            profile: {
                              'Similar Items': this.fields.similarItems,
                              'User Type': this.fields.individualOrCompany,
                            },
                            list: this.fields.newsletter ? 'RRrbrf' : '',
                            email: this.fields.email,
                          })
                        }
                      })
                    } else {
                      this.errorMsg = response.data.errors[0] || response.data.formErrors[0]
                    }
                  }
                })
              }
           
          } catch (error) {
            console.error('reCAPTCHA error:', error);
            this.errorMsg = 'An error occurred. Please try again.';
            this.isSending = false;
          }
        }
      },
    },
  }
</script>

<template>
  <div>
    <transition name="message-fade">
      <div v-if="isSent" data-cy="form-confirm">
        <h4 class="text-xl mb-4">Thank You {{ fields.firstName }}!</h4>

        <div class="wysiwyg">
          <p
            >You will receive an email at the address you submitted ({{ fields.email }}) to confirm
            we have received your inquiry regarding the {{ productTitle }} by {{ brandTitle }}.</p
          >
          <p
            >A Design Associate will assist you with your inquiry and you can expect a response
            within 24 hours, or the next business day.</p
          >
        </div>
      </div>
    </transition>

    <transition name="message-fade">
      <form
        v-if="!isSent"
        id="product-inquiry-form"
        aria-labelledby="quote-form-title"
        @submit.prevent="submitForm"
      >
        <h4 class="mb-4">
          <span class="block text-xs uppercase tracking-widest text-gray-700">Regarding</span>
          <span class="block text-xl">{{ productTitle }}</span>
        </h4>

        <div class="mb-4">
          <span v-for="(option, label) in allOptions" :key="label" class="block text-xs">
            {{ label }}: {{ option }}
          </span>
        </div>

        <div class="mb-4">
          <BaseInputText
            v-model="fields.firstName"
            label="First Name"
            @input="debounceValidation('firstName')"
          />
          <ul v-if="getErrors('firstName').length">
            <BaseError
              v-for="(error, index) in getErrors('firstName')"
              :key="index"
              :error="error"
            />
          </ul>
        </div>

        <div class="mb-4">
          <BaseInputText
            v-model="fields.lastName"
            label="Last Name"
            @input="debounceValidation('lastName')"
          />
          <ul v-if="getErrors('lastName').length">
            <BaseError
              v-for="(error, index) in getErrors('lastName')"
              :key="index"
              :error="error"
            />
          </ul>
        </div>

        <div class="mb-4">
          <BaseInputText
            v-model="fields.email"
            label="Email"
            type="email"
            @input="debounceValidation('email')"
          />
          <ul v-if="getErrors('email').length">
            <BaseError
              v-for="(error, index) in getErrors('email')"
              :key="index"
              tag="li"
              :error="error"
            />
          </ul>
        </div>

        <div class="mb-4">
          <BaseInputText v-model="fields.phone" label="Your Phone Number (optional)" type="tel" />
        </div>

        <div v-if="!loggedIn" class="mb-6">
          <BaseSwitch
            v-model="fields.individualOrCompany"
            :options="['Individual', 'Company']"
            @input="validate('individualOrCompany')"
          />
          <ul v-if="getErrors('individualOrCompany').length">
            <BaseError
              v-for="(error, index) in getErrors('individualOrCompany')"
              :key="index"
              :error="error"
            />
          </ul>
        </div>

        <div v-if="fields.individualOrCompany === 'Company'" class="mb-4">
          <BaseInputText
            v-model="fields.companyName"
            label="Company Name"
            @input="debounceValidation('companyName')"
          />
          <ul v-if="getErrors('companyName').length">
            <BaseError
              v-for="(error, index) in getErrors('companyName')"
              :key="index"
              :error="error"
            />
          </ul>
        </div>

        <div class="mb-4">
          <BaseInputText
            v-model="fields.state"
            label="State"
            @input="debounceValidation('state')"
          />
          <ul v-if="getErrors('state').length">
            <BaseError v-for="(error, index) in getErrors('state')" :key="index" :error="error" />
          </ul>
        </div>

        <div class="mt-8 mb-8">
          <div>
            <h5 class="label text-black">Interested in</h5>
            <BaseCheckbox
              v-if="!isTradeAccount"
              v-model="fields.quote"
              value="1"
              label="Price Quote"
              class="quote-checkbox mb-3"
              @change="handleQuoteChange($event)"
            />
            <BaseCheckbox
              v-model="fields.similarItems"
              value="1"
              label="Similar Items"
              class="quote-checkbox mb-3"
              @change="handleSimilarItemsChange($event)"
            />
          </div>
        </div>

        <div class="mb-4" v-if="!quoteSelected">
          <BaseTextarea
            id="quote_additional_info"
            v-model="fields.additionalInfo"
            @input="debounceValidation('additionalInfo')"
            label="Additional Information (Desired size, configuration, material, etc.)"
          />
          <ul v-if="getErrors('additionalInfo').length">
            <BaseError
              v-for="(error, index) in getErrors('additionalInfo')"
              :key="index"
              :error="error"
            />
          </ul>
        </div>

        <div class="mb-8">
          <BaseCheckbox
            v-model="fields.newsletter"
            value="1"
            :default-checked="checkedOrNot"
            label="Signup for our newsletter"
            class="quote-checkbox"
            @change="handleNewsletterChange($event)"
          />
        </div>

        <div>
          <button
            class="btn bg-yellows text-black uppercase tracking-widest font-bold border-b border-t-0 border-l-0 border-r-0 border-black w-full text-center"
            type="submit"
            data-cy="quote-form-submit"
            :disabled="isSending"
          >
            {{ isSending ? 'Sending' : 'Submit' }}
          </button>
          <BaseError v-if="errorMsg" :error="errorMsg" class="text-center" />
        </div>
      </form>
    </transition>
  </div>
</template>

<style scoped lang="postcss">
  .radioButtonsWrapper {
    &__button {
      margin-right: 0;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        position: relative;
        display: inline-block;
        height: 14px;
        padding-left: 20px;
        font-size: 14px;
        line-height: 14px;
        color: #000;

        span {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          display: block;
          width: 14px;
          height: 14px;
          overflow: hidden;

          &::before {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            content: '';
            border: 1px solid #000;
            border-radius: 100%;
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            display: none;
            width: 100%;
            height: 100%;
            content: '';
            background: #000;
            border: 3px solid #fff;
            border-radius: 100%;
          }
        }

        input {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 5;
          width: 100%;
          height: 100%;
          background: transparent;
          border: none;
          opacity: 0;
        }

        input:checked + span::after {
          display: block;
        }
      }
    }
  }

  .message-fade-enter,
  .message-fade-leave-active {
    opacity: 0;
  }

  .message-fade-enter-active,
  .message-fade-leave-active {
    transition: opacity 0.5s ease;
  }
</style>
